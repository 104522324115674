import React, {useEffect} from 'react'
import {destroy} from 'redux-form'
import {useDispatch, useSelector} from 'react-redux'
import {AppState} from '../../../app/app-store'
import {actionLoadPostalAudit} from '../../other-actions'
import {OtherFormName} from '../../enums/form-name.enum'
import {useParams} from 'react-router-dom'
import {ContentHeading} from '../../../common/content-heading/content-heading'
import {ContentDivider} from '../../../common/content-divider/content-divider'
import {DisplayValue} from '../../../common/display-value/display-value'
import {getAuditStatus, getAuditType} from 'common/audit/audit-utils'
import {formatDate} from 'utils/format/format-date'
import {getMappingTargetName} from './mapping-helper'
import {AuditThemesList} from "../../../common/audit/audit-themes-list";
import {CircularProgress, makeStyles, Theme} from "@material-ui/core";
import {FormBuilderData} from "./FormBuilderData";

interface RouteParams {
    targetId: string
    pin: string
    tenantId: string
    auditId: string
}

type Props = {}
const useStyles = makeStyles((theme: Theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    content: {
        padding: '16px 40px'
    }
}))
export const PostalAuditInfo: React.FC<Props> = props => {
    const audit = useSelector((state: AppState) => state.other.postalAudit)
    const dispatch = useDispatch()
    const classes = useStyles()
    const {tenantId, auditId, pin, targetId} = useParams()
    const formBuilderData: FormBuilderData = audit && audit.formBuilderData ? JSON.parse(audit.formBuilderData) : null;

    useEffect(() => {
        if (tenantId && auditId && pin) {
            dispatch(actionLoadPostalAudit(tenantId, auditId, pin))
        }
        dispatch(destroy(OtherFormName.PostalAudit))

    }, [])
    if (!audit || !audit.formBuilderData) {
        return <ContentHeading><CircularProgress color={'primary'}/></ContentHeading>
    }

    const target: any = audit.targets.length ? audit.targets.filter((target: any) => target.id === targetId || (target.owner && target.owner.id === targetId))[0] : null

    return (
        <div>
            <ContentHeading>
                <div className={classes.header}>
                    <span>Kartlegging tilsyn</span>
                    <span>{audit.caseNumber}</span>
                </div>
            </ContentHeading>
            <ContentDivider/>
            <div className={classes.content}>
                <DisplayValue label={'Type'} value={getAuditType(audit)}/>
                <DisplayValue label={'Objekt'} value={target ? getMappingTargetName(target) : '-'}/>
                <DisplayValue label={'Opprettet'} value={formatDate(audit.created)}/>
                <DisplayValue label={'Status'} value={getAuditStatus(audit)}/>
                {audit.elCompanyTefts && audit.elCompanyTefts.length > 0 && (
                    <AuditThemesList audit={audit}/>
                )}
            </div>
        </div>
    )
}